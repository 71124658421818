import { CheckCircleIcon, DeleteIcon, WarningIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Grid,
  HStack,
  IconButton,
  Input,
  ListItem,
  Select,
  Spinner,
  Switch,
  Text,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react';
import { isEmpty, isNil } from 'lodash';
import React from 'react';
import { QueryObserverResult } from 'react-query';
import { Simulator } from '~utils/types';
import { TIME_ZONES } from '../utils/const';
import { useApiClient } from '../utils/useApiClient';
import ModalConfiguration from './ModalConfiguration';
import ModalFirmware from './ModalFirmware';
import ShowCode from './ShowCode';
import { StorageAvailableInlineEdit } from './StorageAvailableInlineEdit';
import { StorageTotalInlineEdit } from './StorageTotalInlineEdit';
import { UpdatePowerSettingsModal } from './UpdatePowerSettingsModal';
import { VolumeMaxInlineEdit } from './VolumeMaxInlineEdit';
import { VolumeMinInlineEdit } from './VolumeMinInlineEdit';

interface Props {
  simulator: Simulator;
  doRefetch: () => Promise<QueryObserverResult<Simulator, unknown>>;
}

const LED_STRIP_COLOR_OPTIONS = [
  { label: 'red', value: 'RED' },
  { label: 'green', value: 'GREEN' },
  { label: 'blue', value: 'BLUE' },
  { label: 'magenta', value: 'MAGENTA' },
  { label: 'cyan', value: 'CYAN' },
  { label: 'yellow', value: 'YELLOW' },
  { label: 'white', value: 'WHITE' },
  { label: 'unspecified', value: 'UNSPECIFIED' },
];

const CONTROL_LOCK_STATE_OPTIONS = [
  { label: '🔓 Unlocked', value: 'UNLOCKED' },
  { label: '🔊 Volume only', value: 'VOLUME_ONLY' },
  { label: '⚡️ Power only', value: 'POWER_ONLY' },
  { label: '🔒 Locked', value: 'LOCKED' },
];

const PORTS_CONTROL_LOCK_STATE_OPTIONS = [
  { label: '🔓 Unlocked', value: 'UNLOCKED' },
  { label: '🔒 Locked', value: 'LOCKED' },
];

const RELEASE_CHANNEL_OPTIONS = [
  { label: '🦍 Stable', value: 'STABLE' },
  { label: '🧨 Alpha', value: 'ALPHA' },
];

const LOGO_ON_BOOT_OPTIONS = [
  { label: '❌ On', value: 1 },
  { label: '✅ Off', value: 0 },
];

function filterOptionsByAvailability<T>(
  allOptions: Array<{ label: string; value: T }>,
  availableOptions: T[] = [],
) {
  if (availableOptions.length === 0) {
    return allOptions;
  }
  return allOptions.filter(({ value }) => availableOptions.includes(value));
}

export default function SimulatorDetailsActions({ simulator, doRefetch }: Props) {
  const apiClient = useApiClient();
  const updatePowerSettingsModal = useDisclosure();
  const playlists = simulator.shadow.state.playlists?.value ?? [];
  const actualContentSource = simulator.shadow?.state.contentSource?.value;
  const defaultContentSource = simulator.shadow?.state.defaultContentSource?.value;
  const powerSchedule = simulator.shadow.state.powerSchedule;
  const appInstalls = simulator.shadow.state.appInstalls;
  const agentReleaseChannel = simulator.shadow.state.agentReleaseChannel;
  const infraRedControl = simulator.shadow.state.infraRedControl;
  const ledStripColor = simulator.shadow.state.ledStripColor;
  const timeZone = simulator.shadow.state.timeZone;

  const BEHAVIOURS = ['ACCEPT', 'REJECT', 'TIMEOUT'];

  async function onConnect() {
    const connect = simulator.connectionState === 'connected' ? 'disconnect' : 'connect';

    await apiClient.post(`/simulators/${simulator.id}/${connect}`);
    await doRefetch();
  }

  async function onBehaviourChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const behaviour = e.target.value as 'ACCEPT' | 'REJECT' | 'TIMEOUT';
    await apiClient.post(`/simulators/${simulator.id}/configure`, { behaviour });

    await doRefetch();
  }

  type TypeAppContentSource = {
    type: 'app';
    applicationId: string;
    label?: string;
  };

  type TypeBookmarkContentSource = {
    type: 'bookmark';
    index: number;
  };

  type TypeInputContentSource = {
    type: 'input';
    source: string;
  };

  type TypePlaylistContentSource = {
    type: 'playlist';
    playlistId: string;
  };

  type ContentSource =
    | TypeAppContentSource
    | TypeBookmarkContentSource
    | TypeInputContentSource
    | TypePlaylistContentSource;

  async function onContentSourceChange({
    event,
    endpoint,
  }: {
    event: React.ChangeEvent<HTMLSelectElement>;
    endpoint: 'changeContentSource' | 'changeDefaultContentSource';
  }) {
    const contentSource = JSON.parse(event.target.value) as ContentSource;
    await apiClient.post(`/simulators/${simulator.id}/${endpoint}`, { contentSource });

    await doRefetch();
  }

  async function onPowerChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const power = e.target.value as 'ON' | 'STANDBY';
    await apiClient.post(`/simulators/${simulator.id}/changePower`, { power });

    await doRefetch();
  }

  async function onTimeZoneChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const timeZone: string = e.target.value;
    await apiClient.post(`/simulators/${simulator.id}/changeTimeZone`, { timeZone });

    await doRefetch();
  }

  async function onKeyboardControlChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const keyboardControl: string = e.target.value;
    await apiClient.post(`/simulators/${simulator.id}/changeKeyboardControl`, { keyboardControl });

    await doRefetch();
  }

  async function onInfraRedControlChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const infraRedControl: string = e.target.value;
    await apiClient.post(`/simulators/${simulator.id}/changeInfraRedControl`, { infraRedControl });

    await doRefetch();
  }

  async function onPortsControlChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const portsControl: string = e.target.value;
    await apiClient.post(`/simulators/${simulator.id}/changePortsControl`, { portsControl });

    await doRefetch();
  }

  async function onLedStripColorChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const ledStripColor: string = e.target.value;
    await apiClient.post(`/simulators/${simulator.id}/changeLedStripColor`, { ledStripColor });

    await doRefetch();
  }

  async function onAgentReleaseChannelChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const agentReleaseChannel: string = e.target.value;
    await apiClient.post(`/simulators/${simulator.id}/changeAgentReleaseChannel`, {
      agentReleaseChannel,
    });

    await doRefetch();
  }

  async function onLogoOnBootChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const logoOnBoot = e.target.value === 'true' ? false : true;
    await apiClient.post(`/simulators/${simulator.id}/logoOnBoot`, { logoOnBoot });

    await doRefetch();
  }

  async function onMuteChange() {
    const muteToggled = simulator.shadow.state.mute?.value ? false : true;

    await apiClient.post(`/simulators/${simulator.id}/mute`, {
      mute: muteToggled,
    });

    await doRefetch();
  }

  async function onPlaylistsTamper() {
    await apiClient.post(`/simulators/${simulator.id}/tamperPlaylists`);
    await doRefetch();
  }

  async function onAliasChange(e: React.ChangeEvent<HTMLInputElement>) {
    const alias = e.target.value;
    await apiClient.post(`/simulators/${simulator.id}/changeAlias`, { alias });

    await doRefetch();
  }

  function buildContentSourceOptions(simulator: Simulator): ContentSource[] {
    const state = simulator.shadow.state;

    const contentSources: ContentSource[] = state.availableContentSources?.value || [];
    const validPlaylistIds = state.playlists?.value?.map(({ id }) => id) ?? [];
    const validBookmarkIndices = Array.from(
      state.bookmarks?.value.filter((bookmark) => !isEmpty(bookmark)).keys() || [],
    );

    return contentSources.filter((value: ContentSource) => {
      if (value.type === 'bookmark') {
        return validBookmarkIndices.includes(value.index);
      }

      if (value.type === 'playlist') {
        return validPlaylistIds.includes(value.playlistId);
      }

      return true;
    });
  }

  return (
    <Box>
      <Grid templateColumns="auto 1fr" gap={6}>
        {simulator.connectionState === 'connected' ? (
          <Box>
            <HStack spacing="8">
              <CheckCircleIcon color="green.500" />
              <Text>Online</Text>
            </HStack>
          </Box>
        ) : simulator.connectionState === 'disconnected' ? (
          <Box>
            <HStack spacing="8">
              <WarningIcon color="red.500" />
              <Text>Offline</Text>
            </HStack>
          </Box>
        ) : simulator.connectionState === 'connecting' ? (
          <Box>
            <HStack spacing="8">
              <Spinner color="blue.400" />
              <Text>Connecting</Text>
            </HStack>
          </Box>
        ) : (
          <Box>
            <HStack spacing="8">
              <Spinner color="blue.400" />
              <Text>Disconnecting</Text>
            </HStack>
          </Box>
        )}
        <Box>
          <HStack spacing="6">
            <Text>Connect?</Text>
            <Switch
              id="connect"
              defaultChecked={
                simulator.connectionState === 'connected' ||
                simulator.connectionState === 'connecting'
              }
              onChange={() => onConnect()}
            />
          </HStack>
        </Box>

        <Text>Serial Number:</Text>
        <Text>{simulator.configuration.serialNumber}</Text>

        <Text>Alias:</Text>
        <Input
          placeholder={simulator.metadata.alias ?? simulator.configuration.serialNumber}
          onChange={onAliasChange}
        />

        <Text>Commercial Type Number:</Text>
        <Text>{simulator.configuration.commercialTypeNumber}</Text>

        <Text>Agent version:</Text>
        <Text>{simulator.configuration.agentVersion}</Text>

        <Text>Firmware:</Text>
        <Text>
          {simulator.shadow.state.firmwareVersion?.value}{' '}
          {simulator.shadow.state.scalerVersion
            ? `- ${simulator.shadow.state.scalerVersion.value}`
            : undefined}
        </Text>

        <Text>Playlists:</Text>
        <HStack>
          <Text>
            {playlists.length === 0
              ? 'Playlists not configured'
              : playlists.map((p) => p.id).join(', ')}
          </Text>
          <IconButton
            variant="ghost"
            aria-label="tamper playlist"
            icon={<DeleteIcon />}
            onClick={onPlaylistsTamper}
          />
        </HStack>

        <Text>Power Schedule:</Text>
        <HStack>
          <Text>
            {isNil(powerSchedule) ? 'Power schedule not configured' : powerSchedule.value.id}
          </Text>
        </HStack>

        <Text>Behaviour: </Text>
        <HStack>
          <Select
            value={simulator.metadata.behaviour}
            onChange={onBehaviourChange}
            isDisabled={simulator.connectionState !== 'connected'}
          >
            {BEHAVIOURS.map((type) => {
              return (
                <option key={type} value={type}>
                  {type}
                </option>
              );
            })}
          </Select>
        </HStack>

        <Text>Content Source: </Text>
        {simulator.shadow.state.contentSource ? (
          <HStack>
            <Select
              value={JSON.stringify(actualContentSource)}
              onChange={(event) =>
                onContentSourceChange({ event, endpoint: 'changeContentSource' })
              }
              isDisabled={simulator.connectionState !== 'connected'}
            >
              {buildContentSourceOptions(simulator).map((contentSource, index) => {
                return (
                  <option key={index} value={JSON.stringify(contentSource)}>
                    {contentSource.type === 'app'
                      ? `App content - ${contentSource.label ?? contentSource.applicationId}`
                      : contentSource.type === 'input'
                      ? `Input content - ${contentSource.source}`
                      : contentSource.type === 'playlist'
                      ? `Playlist content - ${contentSource.playlistId}`
                      : `Bookmark content - ${
                          simulator.shadow.state.bookmarks?.value[contentSource.index] ?? 'ERROR'
                        }`}
                  </option>
                );
              })}
            </Select>
          </HStack>
        ) : (
          <Text>Unsupported</Text>
        )}

        <Text>Default Content Source: </Text>
        {simulator.shadow.state.defaultContentSource ? (
          <HStack>
            <Select
              value={JSON.stringify(defaultContentSource)}
              onChange={(event) =>
                onContentSourceChange({ event, endpoint: 'changeDefaultContentSource' })
              }
              isDisabled={simulator.connectionState !== 'connected'}
            >
              {buildContentSourceOptions(simulator)
                .concat([{ type: 'input', source: 'LAST INPUT' }])
                .map((contentSource, index) => {
                  return (
                    <option key={index} value={JSON.stringify(contentSource)}>
                      {contentSource.type === 'app'
                        ? `App content - ${contentSource.label ?? contentSource.applicationId}`
                        : contentSource.type === 'input'
                        ? `Input content - ${contentSource.source}`
                        : contentSource.type === 'playlist'
                        ? `Playlist content - ${contentSource.playlistId}`
                        : `Bookmark content - ${
                            simulator.shadow.state.bookmarks?.value[contentSource.index] ?? 'ERROR'
                          }`}
                    </option>
                  );
                })}
            </Select>
          </HStack>
        ) : (
          <Text>Unsupported</Text>
        )}

        <Text>Power: </Text>
        {simulator.shadow.state.power ? (
          <HStack>
            <Select
              value={simulator.shadow.state.power.value}
              onChange={onPowerChange}
              isDisabled={simulator.connectionState !== 'connected'}
            >
              <option value="ON">ON</option>
              <option value="STANDBY">STANDBY</option>
            </Select>
          </HStack>
        ) : (
          <Text>Unsupported</Text>
        )}

        <Text>Led strip color: </Text>
        {ledStripColor ? (
          <HStack>
            <Select
              value={ledStripColor.value}
              onChange={onLedStripColorChange}
              isDisabled={simulator.connectionState !== 'connected'}
            >
              {filterOptionsByAvailability(
                LED_STRIP_COLOR_OPTIONS,
                ledStripColor.supportedValues,
              ).map((option) => {
                return (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                );
              })}
            </Select>
          </HStack>
        ) : (
          <Text>Unsupported</Text>
        )}

        <Text>Keyboard control: </Text>
        {simulator.shadow.state.keyboardControl ? (
          <HStack>
            <Select
              value={simulator.shadow.state.keyboardControl.value ?? undefined}
              onChange={onKeyboardControlChange}
              isDisabled={simulator.connectionState !== 'connected'}
            >
              {filterOptionsByAvailability(
                CONTROL_LOCK_STATE_OPTIONS,
                simulator.shadow.state.keyboardControl.supportedValues,
              ).map((option) => {
                return (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                );
              })}
            </Select>
          </HStack>
        ) : (
          <Text>Unsupported</Text>
        )}

        <Text>Infrared control: </Text>

        {infraRedControl ? (
          <HStack>
            <Select
              value={infraRedControl.value}
              onChange={onInfraRedControlChange}
              isDisabled={simulator.connectionState !== 'connected'}
            >
              {filterOptionsByAvailability(
                CONTROL_LOCK_STATE_OPTIONS,
                infraRedControl.supportedValues,
              ).map((option) => {
                return (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                );
              })}
            </Select>
          </HStack>
        ) : (
          <Text>Unsupported</Text>
        )}

        <Text>Ports control: </Text>
        {simulator.shadow.state.portsControl ? (
          <HStack>
            <Select
              value={simulator.shadow.state.portsControl?.value ?? undefined}
              onChange={onPortsControlChange}
              isDisabled={simulator.connectionState !== 'connected'}
            >
              {filterOptionsByAvailability(
                PORTS_CONTROL_LOCK_STATE_OPTIONS,
                simulator.shadow.state.portsControl.supportedValues,
              ).map((option) => {
                return (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                );
              })}
            </Select>
          </HStack>
        ) : (
          <Text>Unsupported</Text>
        )}

        <Text>Agent Release Channel:</Text>
        {agentReleaseChannel ? (
          <HStack>
            <Select
              value={agentReleaseChannel.value}
              onChange={onAgentReleaseChannelChange}
              isDisabled={simulator.connectionState !== 'connected'}
            >
              {filterOptionsByAvailability(
                RELEASE_CHANNEL_OPTIONS,
                agentReleaseChannel.supportedValues,
              ).map((option) => {
                return (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                );
              })}
            </Select>
          </HStack>
        ) : (
          <Text>Unsupported</Text>
        )}

        <Text>Logo On Boot:</Text>
        {simulator.shadow.state.logoOnBoot ? (
          <HStack>
            <Select
              value={simulator.shadow.state.logoOnBoot.value ? 1 : 0}
              onChange={onLogoOnBootChange}
              isDisabled={simulator.connectionState !== 'connected'}
            >
              {LOGO_ON_BOOT_OPTIONS.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </HStack>
        ) : (
          <Text>Unsupported</Text>
        )}

        <Text>Time Zone:</Text>
        {simulator.shadow.state.timeZone ? (
          <HStack>
            <Select
              value={simulator.shadow.state.timeZone.value}
              onChange={onTimeZoneChange}
              isDisabled={simulator.connectionState !== 'connected'}
            >
              {(timeZone?.supportedValues ?? TIME_ZONES).map((timeZone) => (
                <option key={timeZone} value={timeZone}>
                  {timeZone}
                </option>
              ))}
            </Select>
          </HStack>
        ) : (
          <Text>Unsupported</Text>
        )}

        <Text>Brightness:</Text>
        {simulator.shadow.state.brightness ? (
          <HStack>
            <Text>{simulator.shadow.state.brightness.value}</Text>
          </HStack>
        ) : (
          <Text>Unsupported</Text>
        )}

        <Text>Volume:</Text>
        {simulator.shadow.state.volumeLevel ? (
          <HStack>
            <Text>{simulator.shadow.state.volumeLevel.value}</Text>
          </HStack>
        ) : (
          <Text>Unsupported</Text>
        )}

        <Text>Volume Min:</Text>
        {simulator.shadow.state.volumeMin ? (
          <HStack>
            <VolumeMinInlineEdit
              simulator={simulator}
              onSuccess={async () => {
                await doRefetch();
              }}
            />
          </HStack>
        ) : (
          <Text>Unsupported</Text>
        )}

        <Text>Volume Max:</Text>
        {simulator.shadow.state.volumeMax ? (
          <HStack>
            <VolumeMaxInlineEdit
              simulator={simulator}
              onSuccess={async () => {
                await doRefetch();
              }}
            />
          </HStack>
        ) : (
          <Text>Unsupported</Text>
        )}

        <Text>Muted:</Text>
        {simulator.shadow.state.mute ? (
          <HStack>
            <Text>{simulator.shadow.state.mute.value ? 'yes' : 'no'}</Text>
            <Button onClick={onMuteChange}>
              {simulator.shadow.state.mute.value ? 'unmute' : 'mute'}
            </Button>
          </HStack>
        ) : (
          <Text>Unsupported</Text>
        )}

        <Text>Total storage:</Text>
        {simulator.shadow.state.totalStorage ? (
          <HStack>
            <StorageTotalInlineEdit
              simulator={simulator}
              onSuccess={async () => {
                await doRefetch();
              }}
            />
          </HStack>
        ) : (
          <Text>Unsupported</Text>
        )}

        <Text>Available storage:</Text>
        {simulator.shadow.state.availableStorage ? (
          <HStack>
            <StorageAvailableInlineEdit
              simulator={simulator}
              onSuccess={async () => {
                await doRefetch();
              }}
            />
          </HStack>
        ) : (
          <Text>Unsupported</Text>
        )}

        <Text>Installed apps:</Text>
        <HStack>
          {appInstalls?.value?.length ? (
            <UnorderedList>
              {appInstalls.value.map((appInstall) => {
                return <ListItem key={appInstall.versionName}>{appInstall.applicationId}</ListItem>;
              })}
            </UnorderedList>
          ) : (
            <Text>No installed apps</Text>
          )}
        </HStack>

        <Text>Power Settings:</Text>
        {simulator.shadow.state.powerSettings ? (
          <>
            <Button onClick={updatePowerSettingsModal.onOpen}>Power Settings</Button>
            <UpdatePowerSettingsModal
              simulator={simulator}
              onSuccess={async () => {
                await doRefetch();
              }}
              isOpen={updatePowerSettingsModal.isOpen}
              onClose={updatePowerSettingsModal.onClose}
            />
          </>
        ) : (
          <Text>Unsupported</Text>
        )}

        <Text>Configure:</Text>
        <ModalConfiguration simulator={simulator} refetch={doRefetch} />

        <Text>Firmware:</Text>
        <ModalFirmware simulator={simulator} refetch={doRefetch} />

        <Text>Display Code:</Text>
        <ShowCode simulator={simulator} />
      </Grid>
    </Box>
  );
}
